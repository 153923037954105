import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class ImprintPage extends React.Component {
  render() {
    const siteTitle = "Imprint"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Imprint" keywords={[`imprint`]} />
        <p>
          Lars Wächter <br />
          Goethestraße 17 <br />
          35516 Münzenberg <br />
          Germany
        </p>
        <p>E-Mail: lars97(dot)waechter(at)gmail(dot)com</p>
        <Link to="/">
          <Button marginTop="35px">Home</Button>
        </Link>
      </Layout>
    )
  }
}

export default ImprintPage
